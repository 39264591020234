<template>
	<div
		:data-title="tooltip"
		class="emblem d-inline"
		:class="{ 'tooltip-play': tooltip }"
	>
		<img
			v-if="src"
			:src="src"
			class="medal-image"
			:class="medalClasses"
			@click="$emit('click')"
		/>
		<div
			v-else
			class="medal-image"
			:class="medalClasses"
			@click="$emit('click')"
		></div>
	</div>
</template>

<script>
export default {
	props: {
		src: {
			type: String,
		},
		size: {
			type: String,
			default: "base",
			validator: function (value) {
				return ["xs", "sm", "base", "lg", "xl"].indexOf(value) !== -1;
			},
		},
		skeleton: {
			type: Boolean,
			default: false,
		},
		tooltip: {
			type: String,
		},
	},
	computed: {
		medalClasses() {
			let size = `medal-${this.size}`;
			return { [size]: true, "skeleton-play": this.skeleton };
		},
	},
};
</script>

<style lang="scss" scoped>
@media (min-width: 768px) {
	.tooltip-play {
		position: relative;
		transition: all 0.4s ease;
	}

	.tooltip-play::before {
		content: attr(data-title);
		position: absolute;
		bottom: 120%;
		left: 50%;
		transform: translateX(-50%);

		background-color: var(--caption-bg-color);
		color: var(--gamification-badge-color);
		border-radius: 4px;
		padding: 0 5px;

		font-weight: 700;
		font-size: 10px;
		text-align: center;
		white-space: nowrap;

		min-width: max-content;
		z-index: 1000;
		height: 20px;

		visibility: hidden;
		opacity: 0;
		transition: opacity 0.4s ease;
	}

	.tooltip-play:hover::before {
		visibility: visible;
		opacity: 1;
	}
}

.emblem {
	border-radius: 50%;

	.medal-image {
		border-radius: 50%;
		background-color: var(--bordercolor);
	}

	.medal-xs {
		min-width: 1.25rem; /* 20px */
		height: 1.25rem; /* 20px */
	}

	.medal-sm {
		min-width: 2.5rem; /* 40px */
		height: 2.5rem; /* 40px */
	}

	.medal-base {
		min-width: 3.75rem; /* 60px */
		height: 3.75rem; /* 60px */
	}

	.medal-lg {
		min-width: 5rem; /* 90px */
		height: 5rem; /* 90px */
	}

	.medal-xl {
		min-width: 6.25rem; /* 100px */
		height: 6.25rem; /* 100px */
	}
}
</style>
